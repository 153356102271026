import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    TablePagination,
    IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { getAllActiveWallets, fetchUSDTTxns, fetchMerchants } from 'utils/api';
import { Delete, Edit, RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddConfig from './AddConfig';
import { useDispatch } from 'react-redux';

// table header options
const headCells = [
    {
        id: 'merchant',
        numeric: false,
        label: 'ID',
        align: 'center'
    },
    {
        id: 'initializeTimeMinutes',
        numeric: true,
        label: 'Wallet',
        align: 'left'
    },
    {
        id: 'pendingTimeMinutes',
        numeric: true,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'hash',
        numeric: true,
        label: 'Hash',
        align: 'left'
    },
    {
        id: 'isGet',
        numeric: true,
        label: 'Is Get',
        align: 'left'
    },
    {
        id: 'remark',
        numeric: true,
        label: 'Agent Remark',
        align: 'left'
    },
    {
        id: 'processingTimeMinutes',
        numeric: true,
        label: 'Txn Date',
        align: 'left'
    },
    {
        id: 'desc',
        numeric: true,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
        align: 'center'
    }
];

const USDTTxn = () => {
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [wallets, setMerchants] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await getAllActiveWallets();
            result = result.map((r) => ({
                id: r.walletId || '',
                label: r.walletName || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchUSDTTxns(page, rowsPerPage);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI();
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>USDT Transactions</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={8} />

                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add Transaction
                        </Button>
                        <AddConfig wallets={wallets} open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell align="center">{row.bankTransactionId?.usdtBankTransactionId}</TableCell>
                                    <TableCell>
                                        <div>
                                            <div>{row.bankTransactionId?.walletId}</div>
                                            <div>{row.walletName}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.mode}</TableCell>
                                    <TableCell>{row.hash}</TableCell>
                                    <TableCell>{row.isGet}</TableCell>
                                    <TableCell>{row.agentRemark}</TableCell>
                                    <TableCell>{row.txnDate}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default USDTTxn;
