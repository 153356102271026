import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    IconButton,
    TablePagination,
    Stack,
    TextField,
    InputAdornment
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SearchIcon from '@mui/icons-material/Search';

// assets
import { getAllWallets } from 'utils/api';
import { Edit, RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddWallet from './AddWallet';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'wallet-name',
        numeric: false,
        label: 'Wallet Name',
        align: 'left'
    },
    {
        id: 'chatId',
        numeric: true,
        label: 'Wallet ID',
        align: 'left'
    },
    {
        id: 'active',
        numeric: true,
        label: 'Is Enabled?',
        align: 'left'
    }
];

const date = new Date();
const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
const currentMonth = date.getMonth() < 10 ? `0${parseInt(date.getMonth(), 10) + 1}` : `${parseInt(date.getMonth(), 10) + 1}`;
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

const Wallets = () => {
    const theme = useTheme();
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(500);
    const [search, setSearch] = React.useState('');

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await getAllWallets(page, rowsPerPage);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage);
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');

        if (newString) {
            const newRows = rows.filter((row) => {
                let matches = true;

                const properties = ['walletId', 'walletName'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setRows(newRows);
        } else {
            setRows(data);
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Crypto Wallets</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}

            <CardContent sx={{ p: 0 }}>
                <Grid sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search"
                            value={search}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add Wallet
                        </Button>
                        <AddWallet open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: 500, width: 300 }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                    >
                                        <div>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                            <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.walletName}</TableCell>
                                    <TableCell>{row.walletId}</TableCell>
                                    <TableCell>{row.isEnabled ? 'Yes' : 'No'}</TableCell>
                                    <TableCell align="center">
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Edit">
                                                <IconButton
                                                    onClick={() => setOpen(row)}
                                                    color="primary"
                                                    sx={{
                                                        color: theme.palette.orange.dark,
                                                        borderColor: theme.palette.orange.main,
                                                        '&:hover ': { background: theme.palette.orange.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <Edit sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <ConfirmationModal
                open={selectedBank}
                title="Delete Wallets"
                subTitle="Are you sure, you want to delete selected Wallets"
                handleClose={() => setSelectedBank(false)}
                handleSubmit={deleteProxy}
            /> */}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Wallets;
