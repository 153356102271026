import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    walletId: yup.string().required('Required Field').nullable(),
    walletName: yup.string().required('Required Field').nullable()
});

// ==============================|| ADD DIALOG ||============================== //

const AddWallet = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        walletId: '',
        walletName: 'TRC20',
        isEnabled: true
    });

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (data?.id) {
                setLoading(true);
                axiosServices
                    .post('updateCryptoWallet', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        setDetails({
                            walletId: '',
                            walletName: 'TRC20',
                            isEnabled: true
                        });
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                setLoading(true);
                axiosServices
                    .post('createCryptoWallet', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        setDetails({
                            walletId: '',
                            walletName: 'TRC20',
                            isEnabled: true
                        });
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.id) {
            setDetails(open);
        }
    }, [open]);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="sm" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.id ? 'Edit Wallet' : 'Add Wallet'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="walletName"
                                    className="walletName-select"
                                    name="walletName"
                                    value={formik.values.walletName}
                                    options={['TRC20', 'ERC20']}
                                    onChange={(e, value) => {
                                        formik.setFieldValue('walletName', value);
                                    }}
                                    fullWidth
                                    // onChange={formik.handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                console.log(value, e);
                                                formik.setFieldValue('walletName', value);
                                            }}
                                            label="Wallet Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="walletId"
                                    name="walletId"
                                    label="Wallet ID"
                                    value={formik.values.walletId}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.walletId)}
                                    helperText={formik.errors.walletId}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isEnabled', e.target.checked);
                                            }}
                                            checked={formik.values.isEnabled}
                                        />
                                    }
                                    label="Active"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({
                                    walletId: '',
                                    walletName: 'TRC20',
                                    isEnabled: true
                                });
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddWallet.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddWallet;
