import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import { log } from 'util';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddConfig = ({ wallets, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        // hash: 0,
        // amount: 0,
        payMethod: 'USDT'
    });

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        walletId: open?.bankTransactionId ? null : yup.object().required('Required Field').nullable(),
        hash: yup.string().required('Required Field').nullable(),
        amount: yup.number().required('Required Field').nullable(),
        payMethod: yup.string().required('Required Field').nullable(),
        mode: yup.string().required('Required Field').nullable()
        // agentRemark: yup.string().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);

            if (open?.bankTransactionId) {
                axiosServices
                    .put('updateUSDTTransaction', {
                        ...data,
                        bankTransactionId: data?.bankTransactionId?.usdtBankTransactionId,
                        walletId: data?.bankTransactionId?.walletId
                    })
                    .then((res) => {
                        setLoading(false);
                        formik.resetForm();
                        setDetails({
                            payMethod: 'USDT'
                        });
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        resetForm();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                axiosServices
                    .post('addUSDTTransaction', { ...data, walletId: data?.walletId?.id })
                    .then((res) => {
                        setLoading(false);
                        formik.resetForm();
                        setDetails({
                            payMethod: 'USDT'
                        });
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.bankTransactionId) {
            open.walletId = open?.bankTransactionId?.walletId;
            setDetails(open);
        }
    }, [open]);

    console.log(formik.values);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="xs" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Trasanction Details</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                {open?.bankTransactionId ? (
                                    <TextField
                                        fullWidth
                                        id="walletId"
                                        name="walletId"
                                        label="WalletId"
                                        disabled
                                        value={open?.walletName || null}
                                    />
                                ) : (
                                    <Autocomplete
                                        id="walletId"
                                        className="walletId-select"
                                        name="walletId"
                                        options={wallets?.length ? wallets : []}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('walletId', value);
                                        }}
                                        // value={formik.values.walletId}
                                        // onChange={formik.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('walletId', value);
                                                }}
                                                label="Wallet"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="payMethod"
                                    name="payMethod"
                                    label="Pay Method"
                                    disabled
                                    value={formik.values.payMethod || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payMethod)}
                                    helperText={formik.errors.payMethod}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="hash"
                                    name="hash"
                                    label="Hash"
                                    value={formik.values.hash || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.hash)}
                                    helperText={formik.errors.hash}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    value={formik.values.amount || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.amount)}
                                    helperText={formik.errors.amount}
                                />
                            </Grid>
                            {open?.bankTransactionId ? (
                                <Grid item xs={12}>
                                    <TextField fullWidth id="mode" name="mode" label="Mode" value={formik.values.mode || null} disabled />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="mode"
                                        className="mode-select"
                                        name="mode"
                                        value={formik.values.mode}
                                        options={['CR', 'DR']}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('mode', value);
                                        }}
                                        fullWidth
                                        // onChange={formik.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e, value) => {
                                                    console.log(value, e);
                                                    formik.setFieldValue('mode', value);
                                                }}
                                                label="Mode"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="agentRemark"
                                    name="agentRemark"
                                    label="Agent Remark"
                                    value={formik.values.agentRemark || null}
                                    onChange={formik.handleChange}
                                    // error={Boolean(formik.errors.agentRemark)}
                                    // helperText={formik.errors.agentRemark}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({
                                    payMethod: 'USDT'
                                });
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddConfig.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddConfig;
