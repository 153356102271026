import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SsTransactionDetails from 'views/pay-in-pay-out/SsTransaction/SsTransactionDetails';
import Payout from 'views/pay-in-pay-out/Payout';
import PayoutControl from 'views/support/PayoutControl';
import MerchantUser from 'views/dashboard/MerchantUser';
import USDTTxn from 'views/pay-in-pay-out/USDTTxn';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/AgentViews/dashboard/Default')));
// const DashboardMerchant = Loadable(lazy(() => import('views/AgentViews/dashboard/Merchant')));

// pay in pay out pages
const Transaction = Loadable(lazy(() => import('views/pay-in-pay-out/Transaction')));
const SSTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/SsTransaction')));
const BankTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction')));
const BankTransactionDetails = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction/Details')));

// support pages
const Reports = Loadable(lazy(() => import('views/AgentViews/support/Reports')));

const childrenRoutes = [
    {
        path: '/default',
        element: <DashboardDefault />
    },
    {
        path: '/payment/transaction',
        element: <Transaction />
    },
    {
        path: '/dashboard/merchant-user',
        element: <MerchantUser />
    },
    {
        path: '/payment/bank-transaction',
        element: <BankTransaction />
    },
    {
        path: '/payment/bank-transaction/:bankId',
        element: <BankTransactionDetails />
    },
    {
        path: 'support/reports',
        element: <Reports />
    },
    {
        path: 'payment/usdt-txn',
        element: <USDTTxn />
    }
    // {
    //     path: '/payment/payout',
    //     element: <Payout />
    // }
];

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default AdminRoutes;
