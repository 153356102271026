import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SsTransactionDetails from 'views/pay-in-pay-out/SsTransaction/SsTransactionDetails';
import PG from 'views/Developer/PG';
import Proxy from 'views/Developer/Proxy';
import PayoutDetails from 'views/PG/PayoutDetails';
import PayinDetails from 'views/PG/PayinDetails';
import IPWhitelist from 'views/Developer/IPWhitelist';
import PayoutDashboard from 'views/dashboard/Payout';
import Payout1Dashboard from 'views/dashboard/Payout1';
import Secret from 'views/Developer/Secret';
import AffiliateSettlement from 'views/support/Request/AffiliateSettlement';
import AffilaiteInvoice from 'views/support/Request/AffiliateSettlement/Invoice';
import MerchantConfig from 'views/Developer/MerchantConfig';
import Affiliates from 'views/Developer/Affiliates';
import URL from 'views/Developer/Urls';
import Transactions from 'views/pay-in-pay-out/DebitBank/tranasctions';
import PayinClient from 'views/Developer/PayinClient';
import MerchantMeta from 'views/Developer/MerchantMeta';
import UploadReports from 'views/Developer/Upload';
import UPIApps from 'views/Developer/UPIApps';
import BankTxnReport from 'views/support/BankTxnReport';
import CreatePayout from 'views/Developer/CreatePayout';
import Bank from 'views/Developer/Bank';
import CustomerData from 'views/support/CustomerData';
import BulkWebhook from 'views/Developer/BulkWebhook';
import PayInLevels from 'views/dashboard/PayinLevels';
import LoggedInMerchants from 'views/Developer/LoggedInMerchants';
import SuperAdmins from 'views/Developer/SuperAdmins';
import MerchantIntentConfig from 'views/Developer/MerchantIntentConfig';
import ClickPayConfig from 'views/Developer/ClockPayConfig';
import Dashboard2 from 'views/dashboard/Dashboard2';
import PayoutControl from 'views/support/PayoutControl';
import PayInMeta from 'views/dashboard/Merchant/PayInMeta';
import BulkPayment from 'views/Developer/BulkPayment';
import UpdatePassword from 'views/Developer/UpdatePassword';
import USDTTxn from 'views/pay-in-pay-out/USDTTxn';
// import Bouncer from 'views/Developer/Bouncer';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const DashboardMerchant = Loadable(lazy(() => import('views/dashboard/Merchant')));
const DashboardUser = Loadable(lazy(() => import('views/dashboard/User')));

// pay in pay out pages
const Transaction = Loadable(lazy(() => import('views/pay-in-pay-out/Transaction')));
const SSTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/SsTransaction')));
const Payout = Loadable(lazy(() => import('views/pay-in-pay-out/Payout')));
const PayoutWClient = Loadable(lazy(() => import('views/pay-in-pay-out/PayoutWClient')));
const ManualBank = Loadable(lazy(() => import('views/pay-in-pay-out/Bank')));
const DebitBank = Loadable(lazy(() => import('views/pay-in-pay-out/DebitBank')));
const PayoutBank = Loadable(lazy(() => import('views/pay-in-pay-out/PayoutBank')));
const BankTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction')));
const BankTransactionDetails = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction/Details')));
const BankLogin = Loadable(lazy(() => import('views/pay-in-pay-out/BankLogin')));
const Reconcilation = Loadable(lazy(() => import('views/pay-in-pay-out/Reconcilation')));
const PayIn = Loadable(lazy(() => import('views/dashboard/Merchant/PayIn')));
const PayOut = Loadable(lazy(() => import('views/dashboard/Merchant/Payout')));

// support pages
const Settlement = Loadable(lazy(() => import('views/support/Settlement')));
const PayoutCredit = Loadable(lazy(() => import('views/support/PayoutCredit')));
const Reports = Loadable(lazy(() => import('views/support/Reports')));
const Logs = Loadable(lazy(() => import('views/support/Logs')));
const SettlementRequest = Loadable(lazy(() => import('views/support/Request/Settlement')));
const Webhooks = Loadable(lazy(() => import('views/support/Webhooks')));
const Invoice = Loadable(lazy(() => import('views/support/Request/Settlement/Invoice')));
const PayoutRequest = Loadable(lazy(() => import('views/support/Request/Payout')));

// developer
const Developer = Loadable(lazy(() => import('views/Developer')));

const childrenRoutes = [
    {
        path: '/default',
        element: <DashboardDefault />
    },
    {
        path: '/payout-dashboard',
        element: <PayoutDashboard />
    },
    // {
    //     path: '/dashboard2',
    //     element: <Dashboard2 />
    // },
    {
        path: '/payout1-dashboard',
        element: <Payout1Dashboard />
    },
    {
        path: '/dashboard/merchant',
        element: <DashboardMerchant />
    },
    {
        path: '/merchant/payin/:merchantId',
        element: <PayIn />
    },
    {
        path: '/merchant/payin-meta/:merchantId',
        element: <PayInMeta />
    },
    {
        path: '/payment/debit-banks/:debitBankId',
        element: <Transactions />
    },
    {
        path: '/merchant/payout/:merchantId',
        element: <PayOut />
    },
    {
        path: '/dashboard/user',
        element: <DashboardUser />
    },
    {
        path: '/payment/transaction',
        element: <Transaction />
    },
    {
        path: '/payment/payout',
        element: <Payout />
    },
    {
        path: '/payment/bank-transaction',
        element: <BankTransaction />
    },
    {
        path: '/payment/bank-transaction/:bankId',
        element: <BankTransactionDetails />
    },
    {
        path: 'payment/reconciliation',
        element: <Reconcilation />
    },
    {
        path: 'support/reports',
        element: <Reports />
    },
    {
        path: 'support/webhooks',
        element: <Webhooks />
    },
    {
        path: 'payment/usdt-txn',
        element: <USDTTxn />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const SubAdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default SubAdminRoutes;
